@import '../../styles/variables';

$card-height: 28rem;
$card-height-phones: 25rem;

.projects { 
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: $padding-base;

  &-title { 
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h2 { 
      margin-bottom: 0.4rem;
    }

    h3 { 
      margin-top: 0;
    }
  }
}

.project{
  &-card {
    background-color: transparent;
    perspective: 100rem;
    height: $card-height;

    &:hover .project-card-inner {
      transform: rotateY(180deg);
    }

    &:last-child { 
      grid-column-start: 2;
    }
  }

  &-card-inner {
    height: 100%;
    transition: transform 0.5s;
    transform-style: preserve-3d;
  }

  &-card-front, &-card-back {
    background-color: rgba($black,0.9);
    height: 100%;
    position: absolute;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: $border-radius-base;
  }
  
  &-card-front {
    padding: $padding-base;
    display: flex;
    flex-direction: column;
  }

  &-card-back {
    position: relative;
    transform: rotateY(180deg);
  }

  &-overlay {
    border-radius: $border-radius-base;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
    z-index: 2;
    position: absolute;
  }

  &-screenshot {
    border-radius: $border-radius-base;
    position: absolute;
    width: 100%;
    height: $card-height;
    object-fit: cover;
  }

  &-technology { 
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
  }

  &-title { 
    color: $white;
    text-transform: uppercase;
  }

  &-description{ 
    margin-top: 0;
    color: $grey-light;
    font-weight: 300;
    font-size: $font-size-base;
    line-height: 1.4;
    text-align: center;
  }

  &-technologies {
    margin-top: 0;
    margin-bottom: $padding-large;
    padding: 0;
    align-self: center;
    margin-top: auto;
    list-style: none;
    display: flex;

  }
  
  &-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
    color: $white;
    font-size: 2rem;
    border: 1px solid $grey-light;
    align-self: center;
    padding: 0.4rem 0.8rem;
    border-radius: 0.2rem;

    &:hover { 
      background-color: $white;
      color: $black;
    }
  }
}

@media #{$tablets} {
  .projects {
    grid-template-columns: 1fr 1fr;
  }
  
  .projects-title { 
    grid-column-end: 3;
  }
 }

 @media only screen and (max-width: 600px) {
  .projects {
    grid-template-columns: 1fr;
  }
  .projects-title { 
    grid-column-end: 2;
  }
 }

 @media #{$phones} {
  .project-technology {
    width: 3.5rem;
    height: 3.5rem;
  }

  .project-card, .project-screenshot {
    height: $card-height-phones;
  }

  .project-card:last-child { 
    grid-column-start: unset;
  }
 }